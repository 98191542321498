import React, { useState, Fragment } from 'react'
import { Row, Col } from '../../components/Grid'
import Actor from "../../assets/img/ActorImg.png"
import Img1 from "../../assets/img/SuccessImg1.png"
import Img2 from "../../assets/img/SuccessImg2.png"
import Img3 from "../../assets/img/SuccessImg3.png"
import Img4 from "../../assets/img/Linkedin.png"
import Img5 from "../../assets/img/Instagram.png"
import Img6 from "../../assets/img/Youtube.png"
import { Dialog, Transition } from '@headlessui/react'

function ScheduleModal({ isOpen, setIsOpen }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-50" 
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[calc(100%-20px)] sm:w-[580px] md:w-[800px] lg:w-[1000px] xl:w-[1130px] transform">
                <div className="relative w-full h-[550px] sm:h-[600px] md:h-[700px] lg:h-[800px] xl:h-[850px]">
                  <iframe 
                    src="https://api.leadconnectorhq.com/widget/booking/1LipfM3jNUpGo9CehWOm" 
                    className="w-full h-full border-none"
                    scrolling="no"
                    id="Xw0C27hda3EIrsgdBbm5_1739264271721"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default function Card3() {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false)

  const CardHead = [
    "Industry and Niche Selection", "Crafting Your High-Ticket Offer"
  ]
  const CardList = [
    {
      img: Img1,
      title: "Strategic Direction",
      des: "Know exactly where you're going and how to get there"
    },
    {
      img: Img2,
      title: "Market Position",
      des: "See where the opportunities are in your market"
    },
    {
      img: Img3,
      title: "Scaling Framework",
      des: "Follow a proven path to grow your business"
    },
  ]
  const TargetList = [
    {
      title: "Starters",
      des: "Knowledge & Support Focused",
      keyword: ["Solutions & Results Focused", "Support", "Clear path"]
    },
    {
      title: "Mid Level",
      des: "Growth & Systems Focused",
      keyword: ["Strategic direction", "Proven systems", "Growth metrics"]
    },
    {
      title: "Investors",
      des: "Solutions & Results Focused",
      keyword: ["Done-for-you", "Clear metrics", "Professional"]
    },
  ]
  const MarketList = [
    {
      title: "LinkedIn",
      subtitle: "Professional",
      img: Img4
    },
    {
      title: "Instagram",
      subtitle: "Social",
      img: Img5
    },
    {
      title: "YouTube",
      subtitle: "Search",
      img: Img6
    },
  ]
  const MarketList2 = [
    {
      title: "89",
      subtitle: "Awareness",

    },
    {
      title: "92",
      subtitle: "Recognition",

    },
    {
      title: "94",
      subtitle: "Trust",
    },
    {
      title: "98",
      subtitle: "Authority",
    },
  ]
  const CardItem = [
    {
      title: "Analyze Your Strengths",
      subtitle: "Identify your unique skills, passions, and expertise."
    },
    {
      title: "Market Research",
      subtitle: "Conduct in-depth analysis to uncover profitable opportunities."
    },
    {
      title: "Assess Growth Potential",
      subtitle: "Evaluate the long-term viability and scalability of potential niches."
    },
    {
      title: "Define Your Value Proposition",
      subtitle: "Clearly articulate the unique value you offer to your clients."
    },
    {
      title: "Identify Your Ideal Client",
      subtitle: "Understand who your offer is for and what they truly desire."
    },
    {
      title: "Develop Your Offer Structure",
      subtitle: "Create a compelling offer using our proven formula."
    },
    {
      title: "Craft Persuasive Messaging",
      subtitle: "Use language that resonates with your prospects."
    },
    {
      title: "Validate and Refine",
      subtitle: "Test your offer and refine it based on feedback and results."
    },
  ]
  return (
    <div className='Card3'>
      <div className="Card-Heading">
        <h4 className='text-2xl lg:text-3xl xl:text-[46px] !leading-[130%] font-semibold tracking-[0.72px] text-[#1F2937] mb-4 md:mb-5 lg:mb-[26px]' data-aos="fade-up" data-aos-delay="300">The Foundation of Business Success</h4>
        <div className='lg:flex items-center gap-4 lg:gap-5 xl:gap-8 pb-5 lg:pb-7 mb-5 lg:mb-7 border-b lg:border-b-[1.5px] border-[#E0EDFE]' data-aos="fade-up" data-aos-delay="400">
          {CardHead.map((item, idx) =>
            <div key={idx} className='flex items-center gap-3 lg:gap-4 mb-4 lg:mb-0'>
              <span className='bg-[#3B82F6] size-8 md:size-[38px] flex-[0_0_auto] rounded-full flex items-center justify-center text-white text-base lg:text-lg font-medium tracking-[0.022px] !leading-[1.5]'>{idx + 1}</span>
              <p className='text-lg md:text-2xl lg:text-[32px] text-[#1F2937] font-semibold tracking-[0.08px] !leading-[1.21]'>{item}</p>
            </div>)}
        </div>
      </div>
      <div className="Card-content lg:flex gap-6 lg:gap-[30px]  justify-between">
        <div className="Card-left w-full mb-4 lg:mb-0  ">
          <div className=" Actor-card relative z-[1] bg-white shadow-[0px_1px_4px_0px_rgba(75,85,99,0.12)] rounded-xl lg:rounded-[20px] xl:rounded-[28px] p-5 text-center ">
            <p className='mx-auto flex items-center justify-center mb-5 lg:mb-[26px]' data-aos="fade-up" data-aos-delay="600">
              <svg width="66" height="43" viewBox="0 0 66 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.658203 25.9624C0.658203 11.5719 13.2223 -0.643349 29.9744 0.6953V9.73123C20.5933 9.39657 13.8924 13.9145 12.3847 21.2771C13.7249 20.7751 15.065 20.6078 16.2377 20.6078C22.6035 20.6078 27.1266 25.2931 27.1266 31.1497C27.1266 37.6757 22.1009 42.1936 14.73 42.1936C5.85137 42.1936 0.658203 35.6677 0.658203 25.9624ZM36.3403 25.9624C36.3403 11.5719 48.9044 -0.643349 65.6565 0.6953V9.73123C56.2753 9.39657 49.5744 13.9145 48.0668 21.2771C49.4069 20.7751 50.7471 20.6078 51.9197 20.6078C58.118 20.6078 62.8086 25.2931 62.8086 31.1497C62.8086 37.6757 57.6155 42.1936 50.4121 42.1936C41.5334 42.1936 36.3403 35.6677 36.3403 25.9624Z" fill="#3B82F6" />
              </svg>
            </p>
            <p className='xl:px-10 text-[#111827] text-lg lg:text-2xl font-medium !leading-normal tracking-[0.047px] mb-5 lg:mb-6 xl:mb-[34px]' data-aos="fade-up" data-aos-delay="300">"All you need is the <span className='text-[#4E8EF7]'>plan</span>, the <span className='text-[#4E8EF7]'>road map</span>, and the <span className='text-[#4E8EF7]'>courage </span>to press on to your destination."</p>
            <img className='w-12 lg:w-[60px] h-12 lg:h-[60px] absolute z-10 left-1/2 -bottom-[24px] lg:-bottom-[30px] lg -translate-x-1/2 rounded-full' src={Actor} alt="" />
            <p className='text-[#4E8EF7] text-base lg:text-[23px] font-semibold !leading-[1.5] tracking-[0.036px] absolute -bottom-[75px]  left-1/2 -translate-x-1/2'>- Earl Nightingale</p>
          </div>
          <div className=' bg-white  rounded-xl lg:rounded-[20px] xl:rounded-[28px] p-4 lg:p-5 mt-16 lg:mt-16 xl:mt-28' data-aos="fade-up" data-aos-delay="300">
            <div className='xl:flex items-center gap-2 lg:gap-3 justify-between mb-5 lg:mb-6'>
              <p className='text-base  lg:text-lg xl:text-[26px] mb-3 xl:mb-0 text-[#1F2937] font-medium tracking-[0.05px] !leading-[1.5] text-nowrap'>Foundation for Success</p>
              <p className='text-[#4A7FEF] !leading-[1.7] text-[13px] tracking-[0.012px] bg-[#DBEAFE] rounded-[20px] lg:rounded-[30px] xl:rounded-[50px] py-[5px] px-3 max-w-max text-nowrap  '>Strategic Framework</p>
            </div>
            {CardList.map((item, idx) => (
              <div key={idx} className='linear2 mb-4 lg:mb-5 last:mb-0'>
                <div key={idx} className='xl:flex items-center gap-3 p-4 cursor-pointer rounded-lg lg:rounded-xl' data-aos="fade-up" data-aos-delay={`${idx + 3}00`}>
                  <div className=' mb-3 xl:mb-0 w-8 lg:w-10 h-8 lg:h-10 xl:size-[52px] flex items-center flex-[0_0_auto] justify-center rounded-full border border-[#E0EDFE] group-hover:bg-[#E0EDFE]' ><img src={item.img} className='w-5 lg:w-6 h-5 lg:h-6 ' alt="" /></div>
                  <div>
                    <p className='text-[#1F2937] font-medium text-sm md:text-base lg:text-lg xl:text-xl tracking-[0.04px] mb-[2px] '>{item.title}</p>
                    <p className='text-xs lg:text-sm text-[#4B5563] !leading-[1.5] tracking-[0.013px]'>{item.des}</p>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
        <div className="Card-right w-full bg-white  rounded-xl lg:rounded-[20px] xl:rounded-[28px] p-4 lg:p-5 h-auto ">
          <p className='text-base lg:text-lg xl:text-[26px] text-[#1F2937] font-semibold tracking-[0.05px] !leading-[1.5] mb-5 lg:mb-6 ' data-aos="fade-up" data-aos-delay="300">Strategic Positioning</p>
          <p className='text-[#1F2937] text-base lg:text-lg xl:text-xl !leading-[1.5] tracking-[0.04px]  font-medium mb-3 lg:mb-4' data-aos="fade-up" data-aos-delay="400">Target Audience Values</p>
          {TargetList.map((item, idx) => (
            <div key={idx} className='linear2 mb-4 lg:mb-5 last:mb-0 '>
              <div key={idx} className='p-4 rounded-lg lg:rounded-xl cursor-pointer' data-aos="fade-up" data-aos-delay={`{idx + 3}00`}>
                <p className='text-[#1F2937] text-base lg:text-lg xl:text-xl !leading-[1.5] tracking-[0.04px]  font-medium '>{item.title}</p>
                <p className='text-[#5E6875] text-xs lg:text-sm tracking-[0.013px] !leading-[1.5] mb-1'>{item.des}</p>
                <div className='flex items-center flex-wrap gap-[6px]'>
                  {item.keyword.map((itm, id) => <p key={id} className='text-[#656D7A] text-xs lg:text-[13px] tracking-[0.012px] !leading-[2.7] px-2 bg-[#F9FBFF] rounded-[4px] max-w-max'>{itm}</p>)}
                </div>
              </div>
            </div>
          ))}
          <div className="social">
            <p className='text-[#1F2937] text-base lg:text-lg xl:text-xl !leading-[1.5] tracking-[0.04px] font-medium mt-3 lg:mt-4 mb-3 lg:mb-4' data-aos="fade-up" data-aos-delay="500">Strategic Marketing Performance</p>
            <div className='flex items-center justify-between gap-3 lg:gap-4'>
              {MarketList.map((item, idx) => (
                <div key={idx} className='linear2 w-full' data-aos="fade-up" data-aos-delay={`{idx + 3}00`}>
                  <div className='rounded-lg lg:rounded-xl p-3 lg:p-4 text-center '>
                    <img src={item.img} className='mb-2 w-auto h-5 mx-auto' alt="" />
                    <p className='text-base lg:text-lg font-medium !leading-[1.5] text-[#1F2937]  mb-[2px]'>{item.title}</p>
                    <p className='text-[#2A6BEE] text-xs lg:text-sm !leading-[1.5] tracking-[0.013px]'>{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="Value mt-4 lg:mt-5">
            <p className='text-[#1F2937] text-base lg:text-lg xl:text-xl !leading-[1.5] tracking-[0.04px]  font-medium mt-3 lg:mt-4 mb-3 lg:mb-4' data-aos="fade-up" data-aos-delay="300">Strategic Marketing Performance</p>
            <div className='flex items-center   Performance justify-between gap-3 lg:gap-4'>
              {MarketList2.map((item, idx) => (
                <div key={idx} className=' md:w-full border lg:border-[1.5px]  hover:border-transparent hover:bg-[linear-gradient(130deg,_rgba(224,237,254,0.00)_0%,_#E0EDFE_100.3%)] rounded-lg lg:rounded-xl px-3 py-3 lg:py-4  text-center ' data-aos="fade-up" data-aos-delay={`{idx + 3}00`}>
                  <p className='text-xl lg:text-2xl xl:text-[31px] font-semibold !leading-[1.4] text-[#1F2937] '>{item.title}%</p>
                  <p className='text-[#4B5563] text-xs lg:text-sm !leading-[1.5] tracking-[0.013px]'>{item.subtitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="Process mt-6 lg:mt-10 xl:mt-14">
        <div className='text-center w-[80%] md:w-[60%] lg:w-[41%] mx-auto mb-4 md:mb-6 lg:mb-8 xl:mb-10'>
          <h4 className='text-2xl lg:text-[34px] xl:text-[46px] font-semibold !leading-[1.3] tracking-[0.54px] text-[#1F2937] mb-3 lg:mb-4' data-aos="fade-up" data-aos-delay="400">
            Our Process
          </h4>
        </div>
        <Row>
          {CardItem.map((item, idx) => (
            <Col key={idx} xs={12} md={6} lg={4} className='mb-4 lg:mb-5 w-full h-auto' data-aos="fade-up" data-aos-delay={`{idx + 3}00`}>
              <div className='linear5'>
                <div className='p-3 h-full w-full lg:p-4 border border-[#DCE9FA] hover:border-[rgba(42,107,238,0.00)] hover:bg-[linear-gradient(97deg,_rgba(255,255,255,0.00)_0%,_#E0EDFE_100%)] lg:border-[1.5px] rounded-lg lg:rounded-xl'>
                  <p className='text-base lg:text-lg xl:text-xl !leading-[1.5] font-medium tracking-[0.04px] mb-[2px]'>{item.title}</p>
                  <p className='text-[#5E6875] text-xs lg:text-sm !leading-[1.5] tracking-[0.013px] max-w-[248px]'>{item.subtitle}</p>
                </div>
              </div>
            </Col>
          ))}
          
          {/* Schedule Now Card */}
          <Col xs={12} md={6} lg={4} className='mb-4 lg:mb-5 w-full h-auto' data-aos="fade-up" data-aos-delay="800">
            <div className='linear5'>
              <div className='p-6 h-full w-full flex flex-col items-center justify-center border border-[#DCE9FA] hover:border-[rgba(42,107,238,0.00)] hover:bg-[linear-gradient(97deg,_rgba(255,255,255,0.00)_0%,_#E0EDFE_100%)] lg:border-[1.5px] rounded-lg lg:rounded-xl'>
                <h3 className='text-xl lg:text-2xl font-semibold text-[#2A6BEE] mb-4'>Ready to Get Started?</h3>
                <button 
                  onClick={() => setIsScheduleOpen(true)}
                  className="inline-flex items-center gap-2 px-6 py-3 bg-[#2A6BEE] text-white font-medium rounded-full hover:bg-[#2457c5] transition-colors"
                >
                  Schedule Now
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33334 8H12.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 3.33331L12.6667 8L8 12.6666" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ScheduleModal isOpen={isScheduleOpen} setIsOpen={setIsScheduleOpen} />
    </div>
  )
}
