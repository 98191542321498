import { useRef, useState } from "react";
import { Container, Row, Col } from './Grid'

import poster_1 from '../assets/img/review/1.png'
// import poster_2 from '../assets/img/review/2.png'
import poster_3 from '../assets/img/review/3.png'
import poster_4 from '../assets/img/review/4.png'

const stats = [
    {
        value: "$20M+",
        label: "Revenue Generated",
        color: "text-[#22C55E]"
    },
    {
        value: "8+ Years",
        label: "Proven Experience",
        color: "text-[#3B82F6]"
    },
    {
        value: "500+",
        label: "Success Stories",
        color: "text-[#A855F7]"
    },
    {
        value: "98%",
        label: "Client Satisfaction",
        color: "text-[#EC4899]"
    }
]

export default function Testimonials() {
    const CardItem = [
        {
            title: "Peter Zhang",
            person: "Founder & CEO",
            company: "Steady Growth LLC",
            des: "From September to February we're almost at $100,000 in revenue. I'm very proud of the work.",
            video: 'https://player.vimeo.com/video/1031079685?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1',
            image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
            valueLeft: "$40K+ Monthly Revenue",
            valueRight: "$100K+ Monthly Revenue",
            rating: 5,
        },
        {
            title: "Colt Mosdef",
            person: "Co-Founder & CEO",
            company: "Reward Over Risk LLC",
            des: "I used to do $10,000 per month, now I do $10,000 every single week.",
            video: 'https://player.vimeo.com/video/1031081061?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1',
            image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
            valueLeft: "$10K+ Monthly Revenue",
            valueRight: "$40K+ Monthly Revenue",
            rating: 5,
        },
        {
            title: "Jamal Robinson",
            person: "Co-Founder & CEO",
            company: "Fojo Digital LLC",
            des: "When starting with Train & Scale, we were stuck hovering between the $10,000 to $20,000 per month mark, but Train & Scale helped us to actually surpass the $50,000 per month mark, and now we're hitting that consistently.",
            video: 'https://player.vimeo.com/video/1031077338?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1',
            image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
            valueLeft: "$10K+ Monthly Revenue",
            valueRight: "$50K+ Monthly Revenue",
            rating: 5,
        },
    ]
    const [defaultVideo, setDefaultVideo] = useState({
        video:'https://player.vimeo.com/video/1031086554?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1'
    });

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const changeVideo = (e) => {
        setDefaultVideo(e);
        // videoRef.current.play();
        // setIsPlaying(true);
    }

    return (
        <div className='testimonials common-padding bg-gradient-to-r from-[#E0EDFE] to-[rgba(224,237,254,0.00)]'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center mb-16">
                            <span 
                                className="inline-block text-primary text-sm font-medium tracking-wider mb-4" 
                                data-aos="fade-up"
                            >
                                The Story Behind Our Success
                            </span>
                            
                            <h2 
                                className='text-3xl md:text-4xl lg:text-5xl font-semibold text-[#1F2937] mb-12' 
                                data-aos="fade-up" 
                                data-aos-delay="100"
                            >
                                Building Success Stories Together
                            </h2>

                            <div className="grid md:grid-cols-2 gap-12 max-w-[1200px] mx-auto mb-16">
                                <div 
                                    className="bg-white/50 backdrop-blur-sm rounded-2xl p-8 text-left flex flex-col justify-center"
                                    data-aos="fade-right" 
                                    data-aos-delay="200"
                                >
                                    <div className="text-4xl md:text-5xl font-bold text-[#2563EB] mb-3">
                                        $20M+ 
                                    </div>
                                    <div className="text-lg text-[#4B5563]">
                                        Client Success Stories
                                    </div>
                                </div>
                                
                                <div className="text-left text-[#4B5563] space-y-6" data-aos="fade-left" data-aos-delay="300">
                                    <p className="text-lg leading-relaxed">
                                        What started as a passion for digital growth turned into something bigger than we imagined. For 8 years, we've been learning, adapting, and perfecting what really works in the digital space.
                                    </p>
                                    
                                    <p className="text-lg leading-relaxed">
                                        Working alongside 500+ businesses taught us something crucial - every success story is unique. We don't just apply cookie-cutter solutions. We build systems that match your vision and scale with your ambitions.
                                    </p>
                                    
                                    <p className="text-lg leading-relaxed">
                                        Right now, AI is changing everything. But we see this as an amazing opportunity. We're here to help you build and scale your business before this window of opportunity closes.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="testimonials-grid xl:columns-2 gap-8">
                            <div onClick={() => togglePlay()} className={`mb-6 lg:mb-8 relative cursor-pointer group rounded-lg lg:rounded-[16px] z-[1] xl:rounded-[24px]`} data-aos="fade-up" data-aos-delay="600">
                                <div className="pb-[44%] md:pb-[50%] xl:pb-[56%] relative rounded-lg lg:rounded-[16px] xl:rounded-[24px] overflow-hidden">
                                    <iframe className='bg-[#E0EDFE] w-full h-full object-cover absolute top-0 left-0' src={defaultVideo.video} frameBorder="0"></iframe>
                                </div>
                            </div>
                            {CardItem.map((item, index) => (
                                <div className={`w-full md:w-10/12 lg:w-8/12 xl:w-auto break-inside-avoid-column relative z-[1] ${index == CardItem.length - 1 ? 'mb-0' : 'mb-6 lg:mb-8'}`} key={index} data-aos="fade-up" data-aos-delay={`${index + 3}00`}>
                                    <div className="relative">
                                        <div className='md:ml-[8%] xl:ml-0 w-[160px] md:w-[198px] h-[140px] md:h-[193px] sm:w-[160px] sm:h-[115px] rounded-lg lg:rounded-[12px] bg-[#E0EDFE] p-5 md:py-8 lg:py-10 xl:py-[52px] px-3 lg:px-4 -mb-4 md:mb-0'>
                                            <div className="h-full flex flex-col">
                                                <p className='text-[#1F2937] text-sm lg:text-base font-medium !leading-[1.5] tracking-[0.028px] mb-1'>{item.title}</p>
                                                <p className='text-[#2A6BEE] text-xs !leading-[1.5] mb-2 lg:mb-3'>{item.person}</p>
                                                <p className='text-[#55585A] text-xs !leading-[1.5] mb-1'>{item.company}</p>
                                                <span className="flex items-center gap-[2px]">
                                                    {[...Array(item.rating)].map((r, i) => (
                                                        <svg key={i} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.52447 1.12368C4.67415 0.663027 5.32585 0.663026 5.47553 1.12368L6.23483 3.46056C6.30176 3.66657 6.49374 3.80605 6.71036 3.80605H9.1675C9.65186 3.80605 9.85325 4.42586 9.46139 4.71056L7.47352 6.15483C7.29828 6.28216 7.22495 6.50784 7.29188 6.71385L8.05118 9.05073C8.20086 9.51139 7.67362 9.89445 7.28176 9.60975L5.29389 8.16548C5.11865 8.03816 4.88135 8.03816 4.70611 8.16548L2.71824 9.60975C2.32638 9.89445 1.79914 9.51139 1.94882 9.05073L2.70812 6.71385C2.77505 6.50784 2.70172 6.28216 2.52648 6.15483L0.538611 4.71056C0.146754 4.42586 0.348141 3.80605 0.832503 3.80605H3.28964C3.50626 3.80605 3.69824 3.66657 3.76517 3.46056L4.52447 1.12368Z" fill="#FACC15" />
                                                        </svg>
                                                    ))}
                                                </span>
                                            </div>
                                        </div>
                                        {/* Invisible spacer that maintains original height */}
                                        <div className="h-[45px] sm:block md:hidden"></div>
                                    </div>
                                    <div className='flex gap-5 lg:gap-6 -mt-6 md:mt-0 md:absolute top-[15%] right-6 lg:right-[30px] z-10'>
                                        <button onClick={() => changeVideo(item)} className='relative bg-transparent p-0 border-0 overflow-hidden flex-[0_0_auto] size-20 md:size-[130px] rounded-lg lg:rounded-xl object-cover'>
                                            <span className={`size-6 cursor-pointer z-[2] flex items-center justify-center bg-primary text-white rounded-full border-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}>
                                                <svg className='size-3' width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 9.59607C19.3333 10.3659 19.3333 12.2904 18 13.0602L3 21.7204C1.66667 22.4902 -1.05781e-06 21.528 -9.90511e-07 19.9884L-2.33408e-07 2.66787C-1.6611e-07 1.12827 1.66667 0.166019 3 0.93582L18 9.59607Z" fill="white" />
                                                </svg>
                                            </span>
                                            <img className='w-full h-full object-cover' src={item.image} alt="" />
                                        </button>
                                        <p className='w-[255px] text-[#55585A] text- sm lg:text-base font-medium  !leading-[1.5]'>{`"${item.des}"`}</p>
                                    </div>
                                    <p className='mt-5 lg:mt-8 xl:mt-10 rounded-lg mx-auto max-w-max bg-[#F0FDF4] py-[6px] lg:py-[10px] px-3 xl:px-5 text-[#16A34A] !text-xs !leading-[1.5] flex items-center gap-2 lg:gap-[10px]'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.2856 6H23.1428V12.8571" stroke="#16A34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M23.1426 6L13.4569 15.6857C13.2967 15.8428 13.0813 15.9307 12.8569 15.9307C12.6326 15.9307 12.4172 15.8428 12.2569 15.6857L8.31408 11.7429C8.15385 11.5858 7.93844 11.4978 7.71408 11.4978C7.48972 11.4978 7.2743 11.5858 7.11408 11.7429L0.856934 18" stroke="#16A34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className="text-[#16A34A] font-normal">{item.valueLeft}</span>
                                        <span className="font-medium">{item.valueRight}</span>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
