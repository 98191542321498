import { arrow_up } from "./Icons"

export default function Button({ className, iconClass, text, href, onClick, icon = arrow_up }) {
    const Tag = href ? 'a' : 'button'
    const props = href 
        ? { href, target: '_blank' } 
        : { onClick, type: 'button' }

    return (
        <Tag 
            {...props}
            className={`btn inline-flex items-center justify-between group ${className}`} 
            data-aos="fade-in" 
            data-aos-delay="300"
        >
            <span className="relative -top-[1px]">{text}</span>
            <span className={`icon group-hover:rotate-45 transition-all size-6 md:size-8 flex-[0_0_auto] bg-white text-primary flex items-center justify-center rounded-full ${iconClass}`}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.0396 8.11187C0.805289 8.34618 0.805289 8.72608 1.0396 8.9604C1.27392 9.19471 1.65382 9.19471 1.88813 8.9604L1.0396 8.11187ZM9.13584 1.46416C9.13584 1.13279 8.86721 0.864165 8.53584 0.864164L3.13584 0.864164C2.80446 0.864164 2.53584 1.13279 2.53584 1.46416C2.53584 1.79554 2.80446 2.06416 3.13584 2.06416H7.93584V6.86416C7.93584 7.19554 8.20446 7.46416 8.53584 7.46416C8.86721 7.46416 9.13584 7.19554 9.13584 6.86416L9.13584 1.46416ZM1.88813 8.9604L8.9601 1.88843L8.11157 1.0399L1.0396 8.11187L1.88813 8.9604Z" fill="currentColor" />
                </svg>
            </span>
        </Tag>
    )
}
