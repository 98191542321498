import React from 'react'
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Header from './components/Header'
import Banner from './components/Banner'
import Footer from './components/Footer'
import Faq from './components/Faq'
import Coming from './components/Coming'
import Process from './components/Process'
import Testimonials from './components/Testimonials'
import Aos from 'aos'

export default function App() {
  React.useEffect(() => {
    Aos.init({ duration: 600, once: true })

    // Only add the script on the home page
    if (window.location.pathname === '/') {
      const script = document.createElement('script');
      script.id = 'answer-bot-embed';
      script.setAttribute('data-id', 'cefd072662');
      script.innerHTML = `
        (function() {
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = 'https://app.answeringapp.com/widget-loader.js';
          var firstScript = document.getElementsByTagName('script')[0];
          firstScript.parentNode.insertBefore(script, firstScript);
        })();
      `;
      document.body.appendChild(script);

      // Cleanup function to remove script when component unmounts
      return () => {
        const existingScript = document.getElementById('answer-bot-embed');
        if (existingScript) {
          existingScript.remove();
        }
      };
    }
  }, [])

  // List of paths that should be handled by the funnel
  const funnelPaths = ['/grow', '/booking', '/started', '/schedule', '/callconfirmed']

  // Check if current path is a funnel path
  const isFunnelPath = () => {
    return funnelPaths.some(path => window.location.pathname.startsWith(path))
  }

  // Don't render anything if it's a funnel path
  if (isFunnelPath()) {
    return null
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <Header />
                <Banner />
                <Process />
                <Testimonials />
                <Coming />
                <Faq />
                <Footer />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
      <Analytics />
    </>
  )
} 